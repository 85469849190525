import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Container } from '../../layout/Container';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';
import { createLeadMutation } from '../../../../client/__graphql__/mutations';
import { useMutation } from '@apollo/client';
import { ILeadInput, ILeadResponse } from '../../../__types__/global';
import { useForm } from '../../../utils/hooks/useForm';
import { Form, FormRow, FormWrapper } from '../../ui/form/Form';
import { FormError } from '../../ui/form/FormError';
import { Input } from '../../ui/form/Input';
import { Textarea } from '../../ui/form/Textarea';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { routeConfig } from '../../../__config__/routes';
import { LeadType } from '../../../__types__/generated/globalTypes';
import { Thanks } from '../../layout/Thanks';
import { Section } from '../../layout/Section';

const Boligutvikling: React.FC = () => {
  const [posted, setPosted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const MAIL_MUTATION = createLeadMutation({
    mail: {
      response: `{
            success
          }`
    }
  });

  const [mail, { loading: mailLoading, error }] = useMutation<
    ILeadResponse,
    ILeadInput
  >(MAIL_MUTATION, {
    onCompleted: ({ lead }) => {
      if (lead.mail?.success) {
        setPosted(true);
      }
    }
  });

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Eks. Ola Nordmann',
        value: '',
        label: 'Navn *',
        required: true
      },
      {
        type: 'text',
        name: 'company',
        placeholder: 'Eks. Ditt Firma',
        value: '',
        label: 'Firma *',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'textarea',
        name: 'comment',
        placeholder: 'Allergier/kommentarer',
        value: '',
        label: 'Allergier',
        required: false
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);
      mail({
        variables: {
          input: {
            ...preparedFields,
            referer: window.location.href,
            leadType: LeadType.EPOST,
            mailType: 89,
            zip: '0000'
          }
        }
      });
    }
  });

  return (
    <>
      <Container style={{ marginTop: '66px' }}>
        <Heading tag="h1" center={true} style={{ paddingTop: '2em' }}>
          Boligutvikling 2023
        </Heading>
        <TableWrapper>
          {/*           <div className="table-container">
            <div className="row header-row">
              <div className="cell">Tipspunkt</div>
              <div className="cell">Beskrivelse</div>
            </div>
            <div className="row">
              <div className="cell">17.00-17.15</div>
              <div className="cell">
                Innledning Trender i boligmarkedet, makro og rente v/ adm.dir.
                Grethe Meier, PrivatMegleren
              </div>
            </div>
            <div className="row">
              <div className="cell">17.15-18.00</div>
              <div className="cell">
                Sviktende nyboligsalg – hva sier jussen v/ Kjedesjef
                Nybygg/advokat Christina Lyngtveit-Petersson, PrivatMegleren
              </div>
            </div>
          </div> */}
          <Section>
            <Paragraph className="informasjon">
              Seminaret er et samarbeid mellom advokatfirmaet BAHR og
              PrivatMegleren Nybygg. Det er gratis å delta, men begrenset antall
              plasser. Seminaret finner sted hos BAHR i Tjuvholmen allé 16 i
              Oslo.
            </Paragraph>
            <Paragraph className="informasjon">
              Fyll inn detaljer i skjemaet under.
              <br /> Om dere er flere deltagere fra samme firma, må det sendes
              én påmelding per person.
            </Paragraph>
          </Section>
          {/* <div style={{ padding: '2em' }}>

            <div className="list">
              <ul>
                <li>Hvilke forbehold kan og bør du ta i kjøpekontrakten?</li>
                <li>Når kan forbrukeren trekke seg?</li>
                <li>Hvem har risikoen for byggekostnadene?</li>
                <li>Hva om den utførende entreprenøren går konkurs?</li>
              </ul>
            </div>
          </div> */}

          {/*           <div className="table-container">
            <div className="row header-row">
              <div className="cell">Tipspunkt</div>
              <div className="cell">Beskrivelse</div>
            </div>
            <div className="row">
              <div className="cell">18.00-18.15</div>
              <div className="cell">Pause</div>
            </div>
            <div className="row">
              <div className="cell">18.15-19.00</div>
              <div className="cell">
                Mulighetene v/ advokat/partner Anne Sofie Bjørkholt, BAHR
              </div>
            </div>
          </div> */}

          {/*             <div className="list">
              <ul>
                <li>Hvilke forbehold kan og bør du ta i kjøpekontrakten?</li>
                <li>Når kan forbrukeren trekke seg?</li>
                <li>Hvem har risikoen for byggekostnadene?</li>
                <li>Hva om den utførende entreprenøren går konkurs?</li>
              </ul>
            </div>
          </div>
          <div className="table-container">
            <div className="row header-row">
              <div className="cell">Tipspunkt</div>
              <div className="cell">Beskrivelse</div>
            </div>
            <div className="row">
              <div className="cell">19.00</div>
              <div className="cell">Mingling og tapas</div>
            </div>
          </div> */}

          {/*           <Paragraph className="informasjon">
            Seminaret er gratis. Begrenset antall plasser. For
            påmeldingsdetaljer, se invitasjon. Seminaret finner sted hos BAHR,
            Tjuvholmen allé 16, Oslo.
          </Paragraph> */}
        </TableWrapper>
      </Container>
      <FormWrapper style={{ ...fadeOut, padding: '0 2em' }}>
        <Form onSubmit={handleSubmit} noValidate>
          {error &&
            error.graphQLErrors.map((error, index) => (
              <FormError key={`error_${index}`} message={error.message} />
            ))}
          {fields.map((item, index) => {
            return (
              <FormRow key={`field_${index}`}>
                <Input
                  type={item?.type}
                  name={item?.name}
                  label={item?.label}
                  labelAfter={item?.labelAfter}
                  placeholder={item?.placeholder}
                  value={item?.value}
                  onChange={handleChange}
                  error={item?.error}
                />
              </FormRow>
            );
          })}

          <ButtonGroup>
            <Button
              type="primary"
              colorTheme="gold"
              disabled={loading}
              loading={loading}
            >
              Send inn
            </Button>
            <ButtonLink
              as="a"
              href={routeConfig?.personvern.url}
              className="privacy-link-mobile"
            >
              Personvernpolicy
            </ButtonLink>
          </ButtonGroup>
        </Form>
      </FormWrapper>

      <Thanks style={fadeIn}>
        <Section className="form-section">
          <Heading tag="h2" center={true}>
            Takk for din påmelding
          </Heading>
          <Paragraph center={true}>
            Mer informasjon sendes deg direkte når seminaret nærmer seg.
          </Paragraph>
        </Section>
      </Thanks>
    </>
  );
};

const TableWrapper = styled.div`
  color: white;
  text-align: center;
  .table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .row {
    display: flex;
  }

  .header-row {
    font-weight: bold;
  }

  .cell {
    flex: 1;
    padding: 10px;
    border: 1px solid #e7cfa7;
  }
  .informasjon {
    text-align: center;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .list {
    max-width: 500px;
    margin: 0 auto;
  }

  li {
    text-align: left;
  }

  tr {
    white-space: nowrap;
  }

  table {
    width: 100%;
  }

  th {
    padding-bottom: 11px;
    padding-left: 0;
  }
`;
export default Boligutvikling;
